import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./RaeOverOns.css";

// import foto assets
import FotoAnneleen from "../assets/FotoAnneleen.png";

export default function RaeOverOns({ overOnsActive, setOverOnsActive }) {
  return (
    <>
      {overOnsActive && (
        <div className='sideModal RaeOverOns'>
          <button
            onClick={() => setOverOnsActive(false)}
            className='itemModal-closeButton'
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className='RaeOverOns-content'>
            <h1>
              <span style={{ fontWeight: "300" }}>over</span>{" "}
              <span style={{ fontWeight: "800" }}>rae</span>
            </h1>
            <p>Welkom bij rae! </p>
            <p>
              Ik ben Anneleen Kestens, gepassioneerd goudsmid en trotse eigenaar
              van rae. Ik geloof dat achter elk juweel een bijzonder verhaal
              schuilt, een tastbare herinnering aan momenten die ons leven kleur
              geven; de geboorte van een kind, de magische verbintenis van een
              huwelijk, een teken van appreciatie en vriendschap of een
              herinnering aan een dierbare die altijd in ons hart zal
              voortleven.
            </p>
            <img src={FotoAnneleen} className='RaeOverOns-FotoAnneleen' />
            <p>
              Als goudsmid is het een eer om deze verhalen voor jullie met
              liefde en zorg vast te leggen. Alle juwelen worden gemaakt in mijn
              atelier in het charmante Gent'
            </p>
            <p>
              Wat het leven ook brengt, er is altijd een juweel dat je verhaal
              kan vertellen en je hart kan verwarmen.
            </p>
            <p>Liefs, Anneleen</p>
          </div>
        </div>
      )}
    </>
  );
}
