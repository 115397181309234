// import all images

import goldenZebra1 from "./assets/Goldenzebra1.png";

import goldenZebra2 from "./assets/Goldenzebra2.png";

import goldenZebra3 from "./assets/Goldenzebra3.png";

import organicElegance1 from "./assets/OrganicElegance1.jpg";

import organicElegance2 from "./assets/OrganicElegance2.jpg";

import smoothSilk1 from "./assets/SmoothSilk1.png";

import smoothSilk2 from "./assets/SmoothSilk2.png";

import tourmalineTwist1 from "./assets/TourmalineTwist1.png";

import tourmalineTwist2 from "./assets/TourmalineTwist2.png";

import tourmalineTwist3 from "./assets/TourmalineTwist3.png";

import fingersCrossed1 from "./assets/FingersCrossed1.png";

import fingersCrossed2 from "./assets/FingersCrossed2.png";

import sapphireBlush1 from "./assets/SapphireBlush1.png";

import sapphireBlush2 from "./assets/SapphireBlush2.png";

import sapphireBlush3 from "./assets/SapphireBlush3.png";

import magicalMorganite1 from "./assets/MagicalMorganite1.png";

import magicalMorganite2 from "./assets/MagicalMorganite2.png";

import boldBounce1 from "./assets/BoldBounce1.png";

import boldBounce2 from "./assets/BoldBounce2.png";

import twirlingTemptations1 from "./assets/TwirlingTemptations1.png";

import twirlingTemptations2 from "./assets/TwirlingTemptations2.png";

import edgyAttitude1 from "./assets/EdgyAttitude1.png";

import edgyAttitude2 from "./assets/EdgyAttitude2.png";

import edgyAttitude3 from "./assets/EdgyAttitude3.png";

import perfectMatch1 from "./assets/PerfectMatch1.png";

import perfectMatch2 from "./assets/PerfectMatch2.png";

import hammeredHoops1 from "./assets/HammeredHoops1.png";

import hammeredHoops2 from "./assets/HammeredHoops2.png";

import petiteElegance1 from "./assets/PetiteElegance1.png";

import petiteElegance2 from "./assets/PetiteElegance2.png";

import doubleDelight1 from "./assets/DoubleDelight1.png";

import doubleDelight2 from "./assets/DoubleDelight2.png";

import doubleDelight3 from "./assets/DoubleDelight3.png";

import smoothSerenity1 from "./assets/SmoothSerenity1.png";

import smoothSerenity2 from "./assets/SmoothSerenity2.png";

import smoothSerenity3 from "./assets/SmoothSerenity3.png";

import squareCutStunner1 from "./assets/SquareCutStunner1.png";

import squareCutStunner2 from "./assets/SquareCutStunner2.png";

import rogueRebelry1 from "./assets/RogueRebelry1.png";

import rogueRebelry2 from "./assets/RogueRebelry2.png";

import followTheSun1 from "./assets/FollowTheSun1.png";

import followTheSun2 from "./assets/FollowTheSun2.png";

import belovedInitials1 from "./assets/BelovedInitials1.png";

import belovedInitials2 from "./assets/BelovedInitials2.png";

import belovedInitials3 from "./assets/BelovedInitials3.png";

import belovedInitials4 from "./assets/BelovedInitials4.jpg";

import belovedInitials5 from "./assets/BelovedInitials5.jpg";

import GlitterAndGold1 from "./assets/GlitterAndGold1.jpg";

import GlitterAndGold10mm1 from "./assets/GlitterAndGold10mm1.jpg";

import GlitterAndGold10mm2 from "./assets/GlitterAndGold10mm2.jpg";

import PearlPower1 from "./assets/PearlPower1.jpg";

import PearlPower2 from "./assets/PearlPower2.jpg";

import PearlHoops1 from "./assets/PearlHoops1.jpg";

import PearlHoops2 from "./assets/PearlHoops2.jpg";

import PearlHoops3 from "./assets/PearlHoops3.jpg";

import SquarePair1 from "./assets/SquarePair1.jpg";

import SquarePair2 from "./assets/SquarePair2.jpg";

import GoodThingsEarrings1 from "./assets/GoodThingsEarrings1.jpg";

import GoodThingsEarrings2 from "./assets/GoodThingsEarrings2.jpg";

import GoodThingsArmband1 from "./assets/GoodThingsArmband1.jpg";

import GoodThingsArmband2 from "./assets/GoodThingsArmband2.jpg";

import GoodThingsRing1 from "./assets/GoodThingsRing1.jpg";

import GoodThingsRing2 from "./assets/GoodThingsRing2.jpg";

import GoodThingsRing3 from "./assets/GoodThingsRing3.jpg";

import cadeaubon from "./assets/Cadeaubon.jpg";

import ellure1 from "./assets/Ellure1.jpg";

import sunnysolitaire1 from "./assets/sunnysolitaire1.jpg";

import sunnysolitaire2 from "./assets/sunnysolitaire2.jpg";

import sunnysolitaire3 from "./assets/sunnysolitaire3.jpg";

import initialnecklace1 from "./assets/initialnecklace1.jpg";

import initialnecklace2 from "./assets/initialnecklace2.jpg";

import nameGame1 from "./assets/nameGame1.jpg";

import nameGame2 from "./assets/nameGame2.jpg";

import sunnysideupearrings1 from "./assets/sunnysideupearrings1.jpg";

import sunnysideupearrings2 from "./assets/sunnysideupearrings2.jpg";

import sunnysideupbracelet1 from "./assets/sunnysideupbracelet1.jpg";

import sunnysideupbracelet2 from "./assets/sunnysideupbracelet2.jpg";

import sunnysideupbracelet3 from "./assets/sunnysideupbracelet3.jpg";

const raeCollectionData = [
  {
    itemId: 1,

    naam: "Golden Zebra",

    soort: "ring",

    afbeelding: [goldenZebra1, goldenZebra2, goldenZebra3],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 280,

        prijsStripeId: "price_1OYowfFg5IUZBQhAhTggBlrA",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 400,

        prijsStripeId: "price_1NkBeyFg5IUZBQhAvyty23Qr",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 52, 53, 54, 55, 56],

    spec: {
      ringband: "vierkant 1,5mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 20246,

    naam: "Good Things come in Threes",

    soort: "oorbellen",

    afbeelding: [GoodThingsEarrings1, GoodThingsEarrings2],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 185,

        prijsStripeId: "price_1OaHo4Fg5IUZBQhA2OzfOeHy",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 225,

        prijsStripeId: "price_1OaHp9Fg5IUZBQhAlYmKDVBA",
      },
    ],

    spec: {
      verkochtAls:
        "Verkocht per stuk - indien u een paartje wenst, gelieve twee stuks in uw winkelmandje te plaatsen",

      lengteStaafje: "6mm",

      edelstenen:
        "1,75mm; combinatie op foto 2x padparadscha saffier | 1x lab grown diamant",

      levertermijn: "6-8 weken",

      opmerking:
        "Dit juweel kan perfect gepersonaliseerd worden met edelsteentjes naar keuze. Na het plaatsen van uw bestelling neem ik contact met u op om de gewenste combinatie te bespreken.",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 2,

    naam: "Organic Elegance",

    soort: "ring",

    afbeelding: [organicElegance1, organicElegance2],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 280,

        prijsStripeId: "price_1NkBjOFg5IUZBQhAENwQJ0Lm",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 400,

        prijsStripeId: "price_1NkBkCFg5IUZBQhAaPkO4VSr",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],

    spec: {
      ringband: "vierkant 1,5mm breed, 1,3mm hoog",

      afwerking: ["gehamerd", "gepolijst"],

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 20245,

    naam: "Square Pair",

    soort: "oorbellen",

    afbeelding: [SquarePair1, SquarePair2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 120,

        prijsStripeId: "price_1OaHhUFg5IUZBQhAG4HObaGu",
      },

      {
        materiaalKort: "Zilver Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 155,

        prijsStripeId: "price_1OaHiYFg5IUZBQhAxmrJTncc",
      },
    ],

    spec: {
      verkochtAls: "paar",

      breedte: "2,2mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 3,

    naam: "Smooth Silk",

    soort: "ring",

    afbeelding: [smoothSilk1, smoothSilk2],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 240,

        prijsStripeId: "price_1NkBq5Fg5IUZBQhASAVYyncU",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 315,

        prijsStripeId: "price_1OYop9Fg5IUZBQhAYJ1bpWTv",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],

    spec: {
      ringband: "vierkant 1,5mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 20244,

    naam: "Pearl Hoops",

    soort: "oorbellen",

    afbeelding: [PearlHoops1, PearlHoops2, PearlHoops3],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 140,

        prijsStripeId: "price_1OaHaxFg5IUZBQhA4YOhmbFs",
      },

      {
        materiaalKort: "Zilver Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 175,

        prijsStripeId: "price_1OaHcDFg5IUZBQhAqcFynu6a",
      },
    ],

    spec: {
      verkochtAls: "paar",

      breedte: "2.2mm",

      afmetingenZoetwaterparel: "10,4-11,4mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      opmerking:
        "Zoetwaterparels zijn een natuurproduct, de parels kunnen dus lichtjes verschillen van vorm en kleur.",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 4,

    naam: "Tourmaline Twist",

    soort: "ring",

    afbeelding: [tourmalineTwist1, tourmalineTwist2, tourmalineTwist3],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 345,

        prijsStripeId: "price_1NkBtiFg5IUZBQhADz5jhXyg",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 420,

        prijsStripeId: "price_1NkBuSFg5IUZBQhASTBM27zs",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],

    spec: {
      ringband: "twist1,5mm",

      afwerking: "gepolijst",

      steen: "toermalijn | 4mm",

      levertermijn: "6-8 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 20248,

    naam: "GoodThingsComeInThrees",

    soort: "ring",

    afbeelding: [GoodThingsRing1, GoodThingsRing2, GoodThingsRing3],

    versie: [
      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 1500,

        prijsStripeId: "price_1OaHwJFg5IUZBQhAVihbGkZh",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],

    spec: {
      centraleEdelsteen: "Emerald cut lab grown diamant 0,50ct. E VS1",

      edelstenen:
        "De 3 edelsteentjes verwerkt in de ringband kunnen perfect gepersonaliseerd worden. Na het plaatsen van uw bestelling neem ik contact met u op om de gewenste combinatie te bespreken.",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 5,

    naam: "Fingers Crossed",

    soort: "ring",

    afbeelding: [fingersCrossed1, fingersCrossed2],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 210,

        prijsStripeId: "price_1NkBwtFg5IUZBQhAsZvudwl0",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 265,

        prijsStripeId: "price_1NkBxeFg5IUZBQhAE0S0MNw2",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],

    spec: {
      ringBand: "twist1,5mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 6,

    naam: "Sapphire Blush",

    soort: "ring",

    afbeelding: [sapphireBlush1, sapphireBlush2, sapphireBlush3],

    versie: [
      {
        materiaalKort: "18K rosé goud",

        materiaal: "18k rosé goud",

        prijs: 930,

        prijsStripeId: "price_1OYolJFg5IUZBQhAnqsnArhm",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],

    spec: {
      ringband: "rond1,7mm",

      afwerking: "gepolijst",

      steen: "saffier",

      levertermijn: "6-8 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 8,

    naam: "Bold Bounce",

    soort: "oorbellen",

    afbeelding: [boldBounce1, boldBounce2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 150,

        prijsStripeId: "price_1PD0i9Fg5IUZBQhApMDVm4Av",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 185,

        prijsStripeId: "price_1NkC8ZFg5IUZBQhApOpc5llD",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 1710,

        prijsStripeId: "price_1NkC9DFg5IUZBQhAjgCqkRO8",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 2365,

        prijsStripeId: "price_1NkC9tFg5IUZBQhAyUaIrDUF",
      },
    ],

    spec: {
      verkochtAls: "paar",

      diameter: "20mm",

      draad: "ronde draad, 3mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 9,

    naam: "Twirling Temptations",

    soort: "oorbellen",

    afbeelding: [twirlingTemptations1, twirlingTemptations2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 130,

        prijsStripeId: "price_1QFMDRFg5IUZBQhArx0DsLFb",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 165,

        prijsStripeId: "price_1PD0sSFg5IUZBQhATNX6ZohL",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 1015,

        prijsStripeId: "price_1NkCCBFg5IUZBQhAEB07UuDj",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 1465,

        prijsStripeId: "price_1NkCCsFg5IUZBQhAVJ6lPaJS",
      },
    ],

    spec: {
      verkochtAls: "paar",

      diameter: "20mm",

      draad: "vierkante draad, 2mm",

      afwerking: "twist | gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 10,

    naam: "Edgy Attitude",

    soort: "oorbellen",

    afbeelding: [edgyAttitude1, edgyAttitude2, edgyAttitude3],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 130,

        prijsStripeId: "price_1NkCE4Fg5IUZBQhAidZeI4tr",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 165,

        prijsStripeId: "price_1NkCEjFg5IUZBQhA1sWTrgkJ",
      },
    ],

    spec: {
      verkochtAls: "paar",

      diameter: "18mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 11,

    naam: "Perfect Match",

    soort: "oorbellen",

    afbeelding: [perfectMatch1, perfectMatch2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 105,

        prijsStripeId: "price_1PD1aCFg5IUZBQhAOMWPF4MU",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 140,

        prijsStripeId: "price_1PD3OuFg5IUZBQhAloPXAbmh",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 370,

        prijsStripeId: "price_1PD3O6Fg5IUZBQhAgreUkty9",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 495,

        prijsStripeId: "price_1PD39pFg5IUZBQhAGDalb3m0",
      },
    ],

    spec: {
      verkochtAls:
        "Paar: Staafje en mini hoop. Neem gerust contact op indien je een extra oorbelletje (staafje of mini hoop) wenst te bestellen.",

      afmetingen: "staafje 3x10mm | hoop diameter 7mm",

      afwerking: "gehamerd | gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 12,

    naam: "Hammered Hoops",

    soort: "oorbellen",

    afbeelding: [hammeredHoops1, hammeredHoops2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 120,

        prijsStripeId: "price_1PD13oFg5IUZBQhADMR1dPtf",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 150,

        prijsStripeId: "price_1PD13EFg5IUZBQhAtVaCSTvH",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 540,

        prijsStripeId: "price_1OYohYFg5IUZBQhA7XzAQVs1",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 745,

        prijsStripeId: "price_1NkC6mFg5IUZBQhAkgRmPsW7",
      },
    ],

    spec: {
      verkochtAls: "paar",

      diameter: "21mm",

      draad: "ronde draad, 1,5mm",

      afwerking: "gehamerd | gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 13,

    naam: "Petite Elegance",

    soort: "oorbellen",

    afbeelding: [petiteElegance1, petiteElegance2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 110,

        prijsStripeId: "price_1PD1E4Fg5IUZBQhAVS5JwzMY",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 145,

        prijsStripeId: "price_1PD1EbFg5IUZBQhAZuEf6n3Z",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 405,

        prijsStripeId: "price_1OYoaTFg5IUZBQhAfPREsGL3",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 555,

        prijsStripeId: "price_1NkCKxFg5IUZBQhAEV3E1u9k",
      },
    ],

    spec: {
      verkochtAls: "paar",

      diameter: "10mm",

      draad: "vierkante draad, 1,5mm",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 14,

    naam: "Double Delight",

    soort: "oorbellen",

    afbeelding: [doubleDelight1, doubleDelight2, doubleDelight3],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 130,

        prijsStripeId: "price_1NkCLgFg5IUZBQhAjp9Vd2zn",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 165,

        prijsStripeId: "price_1PD1NSFg5IUZBQhA0LCgmpcZ",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 1010,

        prijsStripeId: "price_1NkCNLFg5IUZBQhAOaOEMLqO",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 1430,

        prijsStripeId: "price_1NkCO6Fg5IUZBQhAzOY9jdhO",
      },
    ],

    spec: {
      verkochtAls: "paar",

      afwerking: "gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 15,

    naam: "Smooth Serenity",

    soort: "armband",

    afbeelding: [smoothSerenity1, smoothSerenity2, smoothSerenity3],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 115,

        prijsStripeId: "price_1NkCP3Fg5IUZBQhA0NaSlGb5",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 150,

        prijsStripeId: "price_1PD0PSFg5IUZBQhAVeysLGbf",
      },
    ],

    maat: ["XS (57mm)", "S (60mm)", "M (63mm)", "L (66mm)"],

    spec: {
      draad: "ronde draad, 2mm",

      afwerking: "licht organische structuur | gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 16,

    naam: "Square-Cut Stunner",

    soort: "armband",

    afbeelding: [squareCutStunner1, squareCutStunner2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 120,

        prijsStripeId: "price_1NkCRxFg5IUZBQhAJrKQOEK8",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 155,

        prijsStripeId: "price_1PD0N5Fg5IUZBQhAUnUFruIB",
      },
    ],

    maat: ["S/M (55x60mm)", "M/L (57x65mm)"],

    spec: {
      draad: "vierkante draad, 2mm",

      afwerking: "gehamerd | gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 17,

    naam: "Rogue Rebelry",

    soort: "armband",

    afbeelding: [rogueRebelry1, rogueRebelry2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 145,

        prijsStripeId: "price_1NkCUyFg5IUZBQhAcgFsB0D2",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 180,

        prijsStripeId: "price_1NkCVgFg5IUZBQhAGxwHiedM",
      },
    ],

    maat: ["XS (57mm)", "S (60mm)", "M (63mm)", "L (66mm)"],

    spec: {
      afwerking: "organische structuur | gepolijst",

      levertermijn: "4-6 weken",

      footer: `Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.`,
    },
  },

  {
    itemId: 20147,

    naam: "Good Things Come In Threes",

    soort: "armband",

    afbeelding: [GoodThingsArmband1],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 485,

        prijsStripeId: "price_1OaHudFg5IUZBQhATtMfA9Ad",
      },
    ],

    spec: {
      edelstenen:
        "3x 1,75mm; combinatie op foto 1x saffier | 1x roze toermalijn | 1x blauwe topaas",

      levertermijn: "6-8 weken",

      opmerking:
        "Dit juweel kan perfect gepersonaliseerd worden met edelsteentjes naar keuze.  Voor een overzicht van geboortesteentjes, klik hier. Na het plaatsen van uw bestelling neem ik contact met u op om de gewenste combinatie te bespreken.",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 19,

    naam: "Follow The Sun",

    soort: "ketting",

    afbeelding: [followTheSun1, followTheSun2],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 1035,

        prijsStripeId: "price_1NkCYnFg5IUZBQhAnoCvMLT4",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 1195,

        prijsStripeId: "price_1OYoV7Fg5IUZBQhATC0yVSOq",
      },
    ],

    lengte: ["45cm"],

    dikte: ["1mm"],

    spec: {
      afmetingenPlaatje: "diameter 20mm | dikte plaatje 1mm",

      afwerking: "gehamerd | gepolijst",

      levertermijn: "4-6 weken",
    },
  },

  {
    itemId: 20,

    naam: "Beloved Initials",

    soort: "ketting",

    afbeelding: [
      belovedInitials1,
      belovedInitials2,
      belovedInitials3,
      belovedInitials4,
      belovedInitials5,
    ],

    versie: [
      {
        materiaal: "925 zilver",
      },

      {
        materiaal: "925 zilver geel goud verguld",
      },

      {
        materiaal: "14k geel goud",
      },

      {
        materiaal: "18k geel goud",
      },
    ],

    versiePrijs: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 Zilver - Small (10mm)",

        sizePlaatje: "Small",

        prijs: 115,

        prijsStripeId: "price_1PD9tuFg5IUZBQhAKy7whg6f",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld - Small (10mm)",

        sizePlaatje: "Small",

        prijs: 150,

        prijsStripeId: "price_1NkCwBFg5IUZBQhADclRTr1I",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud - Small (10mm)",

        sizePlaatje: "Small",

        prijs: 580,

        prijsStripeId: "price_1NkCxlFg5IUZBQhAOicF5hG4",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud - Small (10mm)",

        sizePlaatje: "Small",

        prijs: 730,

        prijsStripeId: "price_1NkCynFg5IUZBQhAysRVTbWi",
      },

      {
        materiaalKort: "Zilver",

        materiaal: "925 Zilver - Medium (15mm)",

        sizePlaatje: "Medium",

        prijs: 117,

        prijsStripeId: "price_1NkD07Fg5IUZBQhAAzxR6ane",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld - Medium (15mm)",

        sizePlaatje: "Medium",

        prijs: 152,

        prijsStripeId: "price_1PD0AcFg5IUZBQhAve7jMxXy",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud - Medium (15mm)",

        sizePlaatje: "Medium",

        prijs: 715,

        prijsStripeId: "price_1NkD1aFg5IUZBQhAHmjH4lnt",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud - Medium (15mm)",

        sizePlaatje: "Medium",

        prijs: 920,

        prijsStripeId: "price_1NkD2KFg5IUZBQhAh1DTSA1Z",
      },

      {
        materiaalKort: "Zilver",

        materiaal: "925 Zilver - Large (20mm)",

        sizePlaatje: "Large",

        prijs: 120,

        prijsStripeId: "price_1NkD39Fg5IUZBQhAR39CkmDF",
      },

      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld - Large (20mm)",

        sizePlaatje: "Large",

        prijs: 155,

        prijsStripeId: "price_1NkD49Fg5IUZBQhAJa53yqK5",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud - Large (20mm)",

        sizePlaatje: "Large",

        prijs: 1035,

        prijsStripeId: "price_1NkD50Fg5IUZBQhAsZpH9tHD",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud - Large (20mm)",

        sizePlaatje: "Large",

        prijs: 1195,

        prijsStripeId: "price_1NkD5bFg5IUZBQhAytId8TLL",
      },
    ],

    lengte: ["45cm"],

    dikte: ["1mm"],

    maat: ["S (10mm)", "M (15mm)", "L (20mm)"],

    spec: {
      tePersonaliserenMetSlagletters:
        "Small is te personaliseren met één letter of hartje. Medium met één of twee letters.  Large met drie of vier letters.",

      beschrijving:
        "Eens je je bestelling hebt geplaatst, contacteren we je via mail om de gewenste letters vast te leggen.",

      diktePlaatje: "1mm",

      afwerking: "slagletters | gepolijst",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 21,

    naam: "Cadeaubon",

    soort: "cadeaubon",

    afbeelding: [cadeaubon],

    versie: [
      {
        materiaal: "Cadeaubon",

        prijsBon: "€ 25",

        prijs: 25,

        prijsStripeId: "price_1NlGE0Fg5IUZBQhAuBqhpcFq",
      },

      {
        materiaal: "Cadeaubon",

        prijsBon: "€ 5O",

        prijs: 50,

        prijsStripeId: "price_1NktgAFg5IUZBQhALAnxcRkF",
      },

      {
        materiaal: "Cadeaubon",

        prijsBon: "€ 75",

        prijs: 75,

        prijsStripeId: "price_1NktgzFg5IUZBQhA8mlNhuDO",
      },

      {
        materiaal: "Cadeaubon",

        prijsBon: "€ 100",

        prijs: 100,

        prijsStripeId: "price_1NkthhFg5IUZBQhAS6yDa6yk",
      },

      {
        materiaal: "Cadeaubon",

        prijsBon: "€ 125",

        prijs: 125,

        prijsStripeId: "price_1NktinFg5IUZBQhAVZLb2pSs",
      },

      {
        materiaal: "Cadeaubon",

        prijsBon: "€ 150",

        prijs: 150,

        prijsStripeId: "price_1NktkxFg5IUZBQhAlzJQUg8w",
      },
    ],

    spec: {
      beschrijving: "Geef wat zonnestralen cadeau",
    },
  },

  {
    itemId: 20241,

    naam: "Glitter&Gold 7mm",

    soort: "oorbellen",

    afbeelding: [GlitterAndGold1],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 845,

        prijsStripeId: "price_1PCzurFg5IUZBQhAH1PAnHM5",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 1030,

        prijsStripeId: "price_1OaHQ9Fg5IUZBQhAm17705FN",
      },
    ],

    spec: {
      verkochtAls: "paar",

      diameter: "7mm",

      breedte: "1.8mm",

      steen: "1,5mm lab grown diamanten",

      afwerking: "gepolijst",

      levertermijn: "6-8 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 20242,

    naam: "Glitter&Gold 10mm",

    soort: "oorbellen",

    afbeelding: [GlitterAndGold10mm1, GlitterAndGold10mm2],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 785,

        prijsStripeId: "price_1OaHSZFg5IUZBQhAQppcjK4v",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 935,

        prijsStripeId: "price_1OaHTiFg5IUZBQhAG4gcAuHa",
      },
    ],

    spec: {
      verkochtAls: "paar",

      diameter: "10mm",

      breedte: "1.4mm",

      steen: "1,2mm lab grown diamanten",

      afwerking: "gepolijst",

      levertermijn: "6-8 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 20243,

    naam: "Pearl Power",

    soort: "oorbellen",

    afbeelding: [PearlPower1, PearlPower2],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 140,

        prijsStripeId: "price_1OaHWuFg5IUZBQhAsMQZoWqq",
      },

      {
        materiaalKort: "Zilver Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 175,

        prijsStripeId: "price_1OaHXnFg5IUZBQhAr6DNXk9S",
      },
    ],

    spec: {
      verkochtAls: "paar",

      afmetingenPlaatje: "15x8mm",

      afmetingenZoetwaterparel: "16-19 X 19-24mm",

      levertermijn: "4-6 weken",

      opmerking:
        "Zoetwaterparels zijn een natuurproduct, de parels kunnen dus lichtjes verschillen van vorm en kleur.",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 22,

    naam: "Ellure",

    soort: "ketting",

    afbeelding: [ellure1],

    versie: [
      {
        materiaalKort: "Zilver",

        materiaal: "925 zilver",

        prijs: 120,

        prijsStripeId: "price_1P6HO3Fg5IUZBQhAIrFwAvAG",
      },

      {
        materiaalKort: "Zilver Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 150,

        prijsStripeId: "price_1P6HRzFg5IUZBQhAIrllqzFq",
      },

      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 660,

        prijsStripeId: "price_1P6HXeFg5IUZBQhARcufydjM",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 850,

        prijsStripeId: "price_1P6HYtFg5IUZBQhAQKGqzyRq",
      },
    ],

    lengte: ["45cm"],

    dikte: ["1mm"],

    spec: {
      lengteStaafje: "25mm",

      tePersonaliserenMetSlagletters:
        "mogelijkheid om aan de vier zijden een persoonlijke gravure aan te brengen",

      levertermijn: "4-6 weken",

      footer:
        "Stuur na het plaatsen van uw bestelling een mailtje naar hello@rae.gold om de gewenste gravure door te geven.",
    },
  },

  {
    itemId: 23,

    naam: "Sunny Solitaire",

    soort: "ring",

    afbeelding: [sunnysolitaire1, sunnysolitaire2, sunnysolitaire3],

    versie: [
      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 970,

        prijsStripeId: "price_1QDjA2Fg5IUZBQhAwbkKS15y",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 52, 53, 54, 55, 56],

    spec: {
      ringband: "rond 1,5mm",

      edelsteen: "lab grown diamant 0,64ct",

      centraleEdelsteen:
        "Centrale Edelsteen: Brilliant cut lab grown diamant 0,64ct",

      afwerking: "gepolijst",

      levertermijn: "6-8 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren (gravure, keuze edelsteen, afwerking); neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },
  {
    itemId: 24,

    naam: "Initial Necklace",

    soort: "ketting",

    afbeelding: [initialnecklace1, initialnecklace2],

    versie: [
      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 755,

        prijsStripeId: "price_1QDjhjFg5IUZBQhA3VEBxT8I",
      },
    ],

    lengte: ["42cm"],

    dikte: ["1mm"],

    spec: {
      afmetingenInitiaal: "8mm hoogte",

      levertermijn: "4-6 weken",

      footer:
        "Stuur na het plaatsen van uw bestelling een mailtje naar hello@rae.gold om het gewenste initiaal en verdere bijzondere wensen door te geven.",
    },
  },

  {
    itemId: 24999,

    naam: "Name Game",

    soort: "ring",

    afbeelding: [nameGame1, nameGame2],

    versie: [
      {
        materiaalKort: "14k Goud",

        materiaal: "14k geel goud",

        prijs: 500,

        prijsStripeId: "price_1QDlFLFg5IUZBQhAu4eCAvwh",
      },

      {
        materiaalKort: "18k Goud",

        materiaal: "18k geel goud",

        prijs: 670,

        prijsStripeId: "price_1QDlJuFg5IUZBQhAUyrDKcw5",
      },
    ],

    maat: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56],

    spec: {
      ringband: "vierkant 1,7mm hoog, 1,5mm breed",

      edelsteen: "1,5mm",

      afwerking: ["naar keuze"],

      levertermijn: "6-8 weken",

      footer:
        "Dit juweel kan perfect gepersonaliseerd worden met edelsteentjes naar keuze. Bekijk gerust ook even het overzicht van geboortesteentjes. Stuur na het plaatsen van uw bestelling een mailtje naar hello@rae.gold om de gewenste edelsteen en specifieke wensen met betrekking tot de ringband en gravure door te geven.",
    },
  },

  {
    itemId: 26,

    naam: "Sunny Side Up",

    soort: "oorbellen",

    afbeelding: [sunnysideupearrings1, sunnysideupearrings2],

    versie: [
      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 180,

        prijsStripeId: "price_1QDkvcFg5IUZBQhAh4S8TcCM",
      },
    ],

    spec: {
      verkochtAls: "paar",

      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },

  {
    itemId: 27,

    naam: "Sunny Side Up | The Bracelet",

    soort: "armband",

    afbeelding: [
      sunnysideupbracelet1,
      sunnysideupbracelet2,
      sunnysideupbracelet3,
    ],

    versie: [
      {
        materiaalKort: "Verguld",

        materiaal: "925 zilver geel goud verguld",

        prijs: 250,

        prijsStripeId: "price_1QDl2KFg5IUZBQhA6Oldzbu5",
      },
    ],

    maat: [17, 19],

    spec: {
      levertermijn: "4-6 weken",

      footer:
        "Indien u vragen heeft over dit juweel of dit juweel wenst aan te passen in functie van uw persoonlijke voorkeuren; neem gerust contact op via hello@rae.gold. Ik help u graag verder.",
    },
  },
];

export default raeCollectionData;
